import React, { useEffect, useState } from "react";
import {
  Col,
  Card,
  Row,
  Button,
  Offcanvas,
  Form,
  InputGroup,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Link } from "react-router-dom";
import Select from "react-select";
import DataTableComponent from "../../components/DataTable";

export default function Ingredients() {
  const [show, setShow] = useState(false);
  const [unit, setUnit] = useState("g"); // Default unit
  const [selectedFamilies, setSelectedFamilies] = useState([]); // State for selected families

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSelect = (eventKey) => {
    setUnit(eventKey);
  };

  const familyOptions = [
    { value: "Oat", label: "Oat" },
    { value: "Wheat", label: "Wheat" },
    { value: "Corn", label: "Corn" },
    // Add more family options here
  ];

  const handleFamilyChange = (selectedOptions) => {
    setSelectedFamilies(selectedOptions);
  };

  const data = [
    {
      name: "Rolled oats",
      costPrice: 8990,
      qtyInGram: 40,
      pricePerGram: 225,
      // family: "Oat",
      isAddOn: false,
      status: "Active",
    },
    {
      name: "Unsweetened almond milk",
      costPrice: 4500,
      qtyInGram: 946,
      pricePerGram: 5,
      // family: "Milk",
      isAddOn: false,
      status: "Active",
    },
  ];

  const columns = [
    { name: "Name", selector: (row) => row.name, sortable: true },
    { name: "Cost Price", selector: (row) => row.costPrice, sortable: true },
    {
      name: "Quantity in Grams",
      selector: (row) => row.qtyInGram,
      sortable: true,
    },
    {
      name: "Price Per Gram",
      selector: (row) => row.pricePerGram,
      sortable: true,
    },
    // { name: 'Family', selector: row => row.family, sortable: true },
    {
      name: "Is Add On",
      selector: (row) => (row.isAddOn ? "Yes" : "No"),
      sortable: true,
    },
    { name: "Status", selector: (row) => row.status, sortable: true },
  ];

  return (
    <React.Fragment>
      <Header onSkin={false} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link href="#">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Ingredients
              </li>
            </ol>
            {/* <h4 className="main-title mb-0">Manage Ingredients</h4> */}
          </div>
        </div>

        <Row className="g-3">
          <Col xs="12">
            <Card className="card-one">
              <Card.Body>
                <Button className="float-end my-3" onClick={handleShow}>
                  Add Ingredient
                </Button>
                <DataTableComponent
                  title={"List of Ingredients"}
                  data={data}
                  columns={columns}
                />
              </Card.Body>
            </Card>

            <Offcanvas show={show} onHide={handleClose} placement="end">
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Add New Ingredient</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                {/* Add your form or content here */}
                <Form>
                  <Form.Group className="mb-3" controlId="formIngredientName">
                    <Form.Label>Ingredient Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter ingredient name"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formCostPrice">
                    <Form.Label>Cost Price</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter cost price"
                      step="0.01"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formQtyInGram">
                    <Form.Label>Quantity</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        placeholder="Enter quantity"
                        step="0.01"
                      />
                      <DropdownButton
                        variant="outline-secondary"
                        title={unit}
                        id="input-group-dropdown-1"
                        align="end"
                        onSelect={handleSelect}
                      >
                        <Dropdown.Item eventKey="g">g</Dropdown.Item>
                        <Dropdown.Item eventKey="mL">mL</Dropdown.Item>
                        <Dropdown.Item eventKey="L">L</Dropdown.Item>
                      </DropdownButton>
                    </InputGroup>
                  </Form.Group>
                  {/* <Form.Group className="mb-3" controlId="formPricePerGram">
                    <Form.Label>Price Per Gram</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter price per gram"
                    />
                  </Form.Group> */}
                  <Form.Group className="mb-3" controlId="formFamily">
                    <Form.Label>Family</Form.Label>
                    <Select
                      isMulti
                      options={familyOptions}
                      value={selectedFamilies}
                      onChange={handleFamilyChange}
                      placeholder="Select family"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formIsAddOn">
                    <Form.Check type="checkbox" label="Is Add On" />
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Save
                  </Button>
                </Form>
              </Offcanvas.Body>
            </Offcanvas>
          </Col>
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
}
