// DataTableComponent.jsx
import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { Form, InputGroup } from 'react-bootstrap';
import styled from 'styled-components';

const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

const FilterWrapper = styled.div`
  margin-bottom: 20px;
`;

const DataTableComponent = ({ data, columns, title }) => {
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredData = data.filter(item => 
    item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <div>
      <FilterWrapper>
        <InputGroup className="mb-3">
          <Form.Control
            type="text"
            placeholder="Search by igredient name"
            aria-label="Search by igredient name"
            aria-describedby="filter-addon"
            value={filterText}
            onChange={e => {
              setFilterText(e.target.value);
              setResetPaginationToggle(!resetPaginationToggle);
            }}
          />
        </InputGroup>
      </FilterWrapper>
      <DataTable
        title={title}
        columns={columns}
        data={filteredData}
        selectableRows
        pagination
        paginationResetDefaultPage={resetPaginationToggle}
        pointerOnHover
        striped
        fixedHeader
        responsive
        progressPending={false}
        // expandableRows
        // expandableRowsComponent={ExpandedComponent}
      />
    </div>
  );
};

export default DataTableComponent;
